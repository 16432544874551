<template>
  <b-modal ref="linkModal" hide-footer title="Preview" centered lazy @hide="hideModal" size="md">
    <div class="text-center">
      <video width="100%" height="100%" controls>
        <source :src="url" type="video/mp4" />
      </video>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'LinkModal',

  props: {
    showModal: { type: Boolean, default: false },

    url: { type: String, default: '' },
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions('products', ['linkProdWithProgram', 'getAllProducts']),
    resetData() {
      this.products = null;
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.linkModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.linkModal.show();
        this.resetData();
      }
    },
  },
};
</script>

<style>
</style>