<template>
  <b-modal
    ref="regTermsModal"
    hide-footer
    title="Registration Terms and Conditions"
    centered
    lazy
    scrollable
    @hide="hideModal"
    :size="size"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="mb-2">
          <b-skeleton width="60%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
        </div>
      </template>

      <div class="d-flex align-items-start">
        <div v-safe-html="registrationTerms" v-if="registrationTerms" />
        <div
          v-safe-html="get(getCurrentSchool, 'registration_terms')"
          v-else-if="get(getCurrentSchool, 'registration_terms')"
        />
        <i v-else>No content to display</i>
      </div>
    </b-skeleton-wrapper>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'RegTermsModal',

  props: {
    showModal: { type: Boolean, default: false },
    size: { type: String, default: 'lg' },
    registrationTerms: { type: String, required: false },
  },

  data() {
    return {
      isLoading: false,
      school: {},
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.regTermsModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.regTermsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
